import reportSchema, { ReportApiEntity } from '../Schemas/Api/Report';

import Report from '../Lib/Report';
import SchemaMapper from './SchemaMapper';

export default class ComparisonGroupMapper
    extends SchemaMapper<Report, ReportApiEntity> {
    schema = reportSchema;

    mapToModel = (data: ReportApiEntity) => {
        return new Report(
            data.arbPfad,
            new Date(Date.parse(data.arbDatum)),
            data.arbTyp1 === 'JABV' ? 'yearly' : 'monthly',
            data.arbTyp2 === 'Privat' ? 'pharmacy' : 'general',
        );
    };

    mapToData = (model: Report) => {
        throw new Error('Report cannot be serialized');
    };
}
