import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Link,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import DataSelect from '../Common/Form/DataSelect';
import Dialog from '../Common/Dialogs/DefaultDialog';
import {
    Link as LinkWrap,
} from 'react-router-dom';
import PharmacyService from '../../Lib/PharmacyService';
import useAuth from '../../Hooks/useAuth';
import useNotifications from '../../Hooks/useNotifications';
import useService from '../../Hooks/useService';

type ActivateMonthlyProps = {
    type?: 'monthlySubscribed' | 'transferGK',
    open: boolean,
    onClose: (cancelledOrError: boolean) => void,
};

const texts = {
    monthlySubscribed: {
        title: 'Monatlichen ATR aktivieren',
        description: 'Möchten sie den monatlichen ApoStar Tara Report für Ihre Apotheke aktivieren?',
        accept: 'Ich habe die Nutzungsbedingungen gelesen und möchte den monatlichen Vergleich aktivieren',
        button: 'aktivieren',
        loading: 'Der monatlichen ApoStar Tara Report wird aktiviert...',
        success: 'Der monatliche ApoStar Tara Report wurde aktiviert',
        error: 'Der monatliche ApoStar Tara Report konnte nicht aktiviert werden',
    },
    transferGK: {
        title: 'AGB akzeptieren',
        description: 'Willkommen beim neuen Portal von Apostar. Wir haben unsere AGB angepasst, diese müssen Sie vor der weiteren Nutzung aktivieren',
        accept: (
            <Typography variant="inherit">
                Ich habe die <LinkWrap component={Link} to="/agb" target="_blank">Nutzungsbedingungen</LinkWrap> gelesen und akzeptiere diese
            </Typography>
        ),
        button: 'Nutzungsbedingungen akzeptieren',
        loading: 'Nutzungsbedingungen werden aktzeptiert...',
        success: 'Die AGB wurden akzeptiert',
        error: 'Die AGB konnten nicht aktiviert werden',
    },
}

const ActivateMonthly = ({
    type = 'monthlySubscribed',
    open,
    onClose,
}: ActivateMonthlyProps) => {
    const [accepted, setAccepted] = useState(false);
    const [extra, setExtra] = useState<{ software?: any }>({});
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const service = useService<PharmacyService>('pharmacy');
    const { authentication } = useAuth();
    const { addError, addSuccess } = useNotifications();

    // Handle actions
    const handleAction = (action: string) => {
        if (action === texts[type].button) {
            if (!accepted || !authentication) {
                setError('Bitte bestätigen sie AGB');
                return;
            }
            const pharmacy = authentication.pharmacy;
            if (type === 'monthlySubscribed' && !extra.software.begID) {
                setError('Bitte geben Sie ihre Apothekensoftware an');
                return;
            } else if (type === 'monthlySubscribed') {
                pharmacy.software = extra.software.begID;
            }
            setIsLoading(true);
            pharmacy[type] = true;
            service.saveRaw(pharmacy)
                .then(() => {
                    addSuccess(texts[type].success);
                    window.location.reload();
                    onClose(false);
                })
                .catch(() => {
                    addError(texts[type].error);
                    setIsLoading(false);
                });
        } else {
            onClose(true);
        }
    };

    // Loading ui
    if (isLoading) {
        return (
            <Dialog
                title={texts[type].title}
                open={open}
                onClose={() => {}}
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CircularProgress />
                    <Typography variant="body1" component="p">
                        {texts[type].loading}
                    </Typography>
                </Box>
            </Dialog>
        );
    }

    return (
        <Dialog
            title={texts[type].title}
            open={open}
            onClose={() => onClose(true)}
            actions={[texts[type].button, 'abbrechen']}
            onAction={handleAction}
            stackedActions
            actionProps={{
                [texts[type].button]: { variant: 'contained', color: 'primary', fullWidth: true },
                abbrechen: { color: 'default', fullWidth: true },
            }}
        >
            <Typography variant="body1" component="p" color="textSecondary" gutterBottom>
                {texts[type].description}
            </Typography>
            {type === 'monthlySubscribed' ? (
                <FormControl component="fieldset" fullWidth>
                    <DataSelect
                        label="Verwendete Apothekensoftware"
                        type="software"
                        name="software"
                        value={0}
                        onChange={({ target: { value } }: any) => setExtra({ software: value })}
                        onBlur={() => {}}
                    />
                </FormControl>
            ) : null}
            <FormControl error={error !== ''} component="fieldset">
                <FormControlLabel
                    control={(<Checkbox onChange={({ target: { checked }}) => setAccepted(checked)} />)}
                    label={texts[type].accept}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Dialog>
    );
};

export default ActivateMonthly;
