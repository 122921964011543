import { Box, Divider, Grid, Theme, Tooltip } from '@material-ui/core';
import {
    General,
    PharmacyLeaderOccupation,
    PharmacyNightDutiesPerYear,
} from '../../Lib/YearlyData';
import React, { useState } from 'react';
import { dateFormat, localDate } from '../../Lib/Helper';

import CompareableNumberInput from './CompareableNumberInput';
import CompareableNumbersHeader from './CompareableNumbersHeader';
import DateInput from './DateInput';
import { Error } from '../../Lib/YearlyDataValidation';
import FieldError from './FieldError';
import LoadingButton from '../Common/LoadingButton';
import SelectInput from './SelectInput';
import YesNoInput from './YesNoInput';
import { getErrorsForField } from '../../Lib/ValidationHelpers';
import { makeStyles } from '@material-ui/core/styles';
import useSalesData from './useSalesData';

// Define fields available
export const GeneralFields: Record<keyof General, string> = {
    A2_LJ: 'Beginn des Wirtschaftsjahres',
    A3_LJ: 'Ende des Wirtschaftsjahres',
    A6_LJ: 'Fungiert Ihre Apotheke als Einkäufer einer Einkaufsgemeinschaft?',
    A7_LJ: 'Haben Sie eine Filialapotheke?',
    A4_LJ: 'Wurde die Apotheke durch einen alleinstehenden Konzessionär, Pächter oder angestellten Apothekenleiter ohne pharmazeutische Mitarbeiter (Aspirant, Urlaubs- oder Krankenvertreter ausgenommen) geführt?',
    A5_LJ: 'Im Wirtschaftsjahr war der Apothekenleiter',
    A19_LJ: 'Apotheke (Geschäftslokal) ist eine Eigenimmobilie?',
    A9_LJ: 'Nachtdienste pro Wirtschaftsjahr',
    A13_LJ: 'Gesamtfläche',
    A14_LJ: 'Offizinfläche',
    A15_LJ: 'Kundenzahl pro Jahr',
    A16_LJ: 'Rezeptzahl pro Jahr',
    A18_LJ: 'Pro Jahr abgesetzte Krankenkassenpackungen',
    A17_LJ: 'Pro Jahr abgesetzte Privatpackungen',
};

type GeneralFormProps = {
    year: number,
    pharmacyId: number,
    isCurrentYear: boolean,
    initialValues?: General,
    compareValues?: General,
    onChange?: (values: General) => void,
    errors?: Array<Error>,
};

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    arrow: {
        color: theme.palette.primary.main,
    },
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 14,
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: 2,
    },
    icon: {
        padding: 0,
        '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
        },
    },
}));

const tooltipText = "Anzahl der Rezepte und Krankenkassenpackungen werden durch Anklicken des Buttons über eine Schnittstelle von der Pharmazeutischen Gehaltskasse geladen";

const yearlyPeriodEndDate = (date: Date, year: number): Date => {
    const fixedMaxDate = new Date(year + 1, 6, 29);

    // There are browser limitations where date could not be parsed as a date
    if (!date) {
        return fixedMaxDate;
    }

    const next = new Date(date);
    next.setFullYear(date.getFullYear() + 1);
    next.setDate(next.getDate() - 1);

    return fixedMaxDate < next ? fixedMaxDate : next;
};

const minDate = (a: Date, b?: Date) => (!b || a > b ? a : b);

const GeneralForm: React.FC<GeneralFormProps> = ({
    year,
    onChange = () => {},
    initialValues,
    compareValues,
    errors = [],
    pharmacyId,
}) => {
    // Value reference, only setter needs to be exposed
    const [values, setValues] = useState<General>(initialValues || {});
    // We use a little trickery to determine if to date was manually changed
    const [manualToDateSet, setManualToDateSet] = useState<number>(initialValues?.A3_LJ ? 2 : 1);

    // Value change callback for individual field
    const onValueChange = <T extends keyof General, U = General[T]>(newValue?: U, id?: T) => {
        // id is requed
        if (!id) {
            return;
        }

        setValues((prev) => {
            const nextValues = {
                ...prev,
                [id]: newValue,
            };
            onChange(nextValues);
            return nextValues;
        });
    };

    // Special treatment for dates
    const onChangeDate = (newValue: Date, field: 'A2_LJ' | 'A3_LJ') => {
        // Manual change is determined when the number jumps above 1. 1 it is at the beginning, for
        // every change it is increased, that means a manual change pushes it to 2. However this non
        // manual change first sets it to 0, so the change handler fires and increases it to 1.
        if (!newValue) {
            // There are browser limitations where date could not be parsed as a date
            return;
        }

        if (field === 'A2_LJ' && manualToDateSet < 2) {
            setManualToDateSet(0);
            setValues((prev) => ({
                ...prev,
                A3_LJ: yearlyPeriodEndDate(newValue, year),
            }));
        } else {
            setManualToDateSet((prev) => (prev + 1));
            if (field === 'A2_LJ' && values.A3_LJ && yearlyPeriodEndDate(newValue, year) < values.A3_LJ) {
                setValues((prev) => ({
                    ...prev,
                    A3_LJ: yearlyPeriodEndDate(newValue, year),
                }));
            }
        }

        onValueChange(newValue, field);
    };

    // Loading gk data
    const { load, isLoading } = useSalesData({
        onLoaded: (record) => {
            setValues((prev) => ({
                ...prev,
                A16_LJ: record.recipeCount,
                A18_LJ: record.packageCount,
            }));
            onValueChange(record.packageCount, "A18_LJ");
        },
        pharmacyId,
        fiscalYear: [values.A2_LJ, values.A3_LJ],
    });

    const onFocused = (event: React.FocusEvent<HTMLElement>) => {
        if (
            event.relatedTarget
            && event.target.getBoundingClientRect().top > (window.innerHeight - 100)
        ) {
            window.scrollBy(0, 80);
        }
    };

    const classes = useStyles();
    return (
        <>
            <FieldError
                errors={getErrorsForField(`general.A2_LJ`, errors)}
            >
                <DateInput
                    id="A2_LJ"
                    label={GeneralFields.A2_LJ}
                    initialValue={initialValues?.A2_LJ}
                    onChange={onChangeDate as any}
                    type="start"
                    min={`${year - 1}-08-01`}
                    max={`${year}-12-01`}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A3_LJ`, errors)}
            >
                <DateInput
                    id="A3_LJ"
                    label={GeneralFields.A3_LJ}
                    initialValue={initialValues?.A3_LJ}
                    value={values?.A3_LJ}
                    onChange={onChangeDate as any}
                    type="end"
                    min={localDate(minDate(new Date(year, 7, 1), values?.A2_LJ))}
                    max={
                        values?.A2_LJ
                            ? localDate(yearlyPeriodEndDate(values?.A2_LJ, year))
                            : `${year + 1}-06-30`
                    }
                />
            </FieldError>
            <Divider className={classes.divider} />
            <FieldError
                errors={getErrorsForField(`general.A6_LJ`, errors)}
            >
                <YesNoInput
                    id="A6_LJ"
                    label={GeneralFields.A6_LJ}
                    initialValue={initialValues?.A6_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A7_LJ`, errors)}
            >
                <YesNoInput
                    id="A7_LJ"
                    label="Haben Sie eine Filialapotheke?"
                    initialValue={initialValues?.A7_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A4_LJ`, errors)}
            >
                <YesNoInput
                    id="A4_LJ"
                    label={GeneralFields.A4_LJ}
                    initialValue={initialValues?.A4_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A5_LJ`, errors)}
            >
                <SelectInput
                    id="A5_LJ"
                    label={GeneralFields.A5_LJ}
                    options={PharmacyLeaderOccupation}
                    initialValue={initialValues?.A5_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A19_LJ`, errors)}
            >
                <YesNoInput
                    id="A19_LJ"
                    label={GeneralFields.A19_LJ}
                    initialValue={initialValues?.A19_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A9_LJ`, errors)}
            >
                <SelectInput
                    id="A9_LJ"
                    label={GeneralFields.A9_LJ}
                    options={PharmacyNightDutiesPerYear}
                    initialValue={initialValues?.A9_LJ}
                    onChange={onValueChange as any}
                    onFocused={onFocused}
                />
            </FieldError>
            <Divider className={classes.divider} />
            <CompareableNumbersHeader year={year} />
            <FieldError
                errors={getErrorsForField(`general.A13_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A13_LJ"
                    label={GeneralFields.A13_LJ}
                    type="m2"
                    trend="pct"
                    compareValue={compareValues?.A13_LJ}
                    initialValue={initialValues?.A13_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A14_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A14_LJ"
                    label={GeneralFields.A14_LJ}
                    type="m2"
                    trend="pct"
                    compareValue={compareValues?.A14_LJ}
                    initialValue={initialValues?.A14_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A15_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A15_LJ"
                    label={GeneralFields.A15_LJ}
                    type="number"
                    trend="pct"
                    compareValue={compareValues?.A15_LJ}
                    initialValue={initialValues?.A15_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A17_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A17_LJ"
                    label={GeneralFields.A17_LJ}
                    type="pcs"
                    trend="pct"
                    compareValue={compareValues?.A17_LJ}
                    initialValue={initialValues?.A17_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
            <Grid container spacing={1} alignItems="center" style={{ marginBottom: 10 }}>
                <Grid item sm={4} xs={12} />
                <Grid item xs={6} sm={4}>
                    <Tooltip title={tooltipText} placement="top" arrow classes={classes}>
                        <Box>
                            <LoadingButton
                                loading={isLoading}
                                onClick={load}
                                variant="outlined"
                                color="primary"
                                size="small"
                                fullWidth
                                disabled={!values.A2_LJ || !values.A3_LJ}
                                tabIndex={-1}
                            >
                                Gehaltskassedaten laden
                            </LoadingButton>
                        </Box>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} sm={4}>
                    {values.A2_LJ && values.A3_LJ ? (
                        <>für {dateFormat(values.A2_LJ)} - {dateFormat(values.A3_LJ)}</>
                    ) : null}
                </Grid>
            </Grid>
            <FieldError
                errors={getErrorsForField(`general.A16_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A16_LJ"
                    label={GeneralFields.A16_LJ}
                    type="number"
                    trend="pct"
                    compareValue={compareValues?.A16_LJ}
                    initialValue={initialValues?.A16_LJ}
                    liveValue={initialValues?.A16_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
            <FieldError
                errors={getErrorsForField(`general.A18_LJ`, errors)}
            >
                <CompareableNumberInput
                    id="A18_LJ"
                    label={GeneralFields.A18_LJ}
                    type="pcs"
                    trend="pct"
                    compareValue={compareValues?.A18_LJ}
                    initialValue={initialValues?.A18_LJ}
                    liveValue={initialValues?.A18_LJ}
                    onChange={onValueChange as any}
                />
            </FieldError>
        </>
    );
}

export default GeneralForm;
