import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
import { FormGroup, FormHelperText, Link } from '@material-ui/core';
import { MenuItem, Typography } from '@material-ui/core';

import DataSelectField from '../Common/Form/DataSelectField';
import HelperField from '../Common/Form/HelperField';
import {
    Link as LinkWrap,
} from 'react-router-dom';
import LocationMultiSelect from '../Common/Form/LocationMultiSelect';
import React from 'react';
import { localDate } from '../../Lib/Helper';

/**
 * Intermediate for multiselect of location.
 */
const FormikLocationSelect = ({
    field,
}: FieldProps) => (
    <LocationMultiSelect
        {...field}
    />
);

/**
 * The component
 */
const Form = ({
    isSignUp = true,
    displaySoftware = true,
    displayDates = true,
}: { isSignUp?: boolean, displaySoftware?: boolean, displayDates?: boolean }) => {
    const year = new Date().getFullYear();
    const { values }: { values: any } = useFormikContext();

    return (
        <>
            <Typography variant="h5" component="h5" color="textPrimary" gutterBottom>
                Apotheke
            </Typography>
            <FormGroup>
                {displayDates ? (
                    <>
                        <Field
                            component={TextField}
                            label="Gründungsjahr"
                            margin="normal"
                            name="foundingYear"
                        />
                        <Field
                            component={TextField}
                            select
                            label="Bilanzstichtag"
                            name="dateOfBalance"
                            variant="outlined"
                        >
                            <MenuItem value={localDate(new Date(year, 1, 0))}>31.01.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 2, 0))}>28./29.02.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 3, 0))}>31.03.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 4, 0))}>30.04.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 5, 0))}>31.05.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 6, 0))}>30.06.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 7, 0))}>31.07.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 8, 0))}>31.08.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 9, 0))}>30.09.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 10, 0))}>31.10.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 11, 0))}>30.11.</MenuItem>
                            <MenuItem value={localDate(new Date(year, 11, 31))}>31.12.</MenuItem>
                        </Field>
                    </>
                ) : null}
                <Field
                    component={DataSelectField}
                    label="Öffnungszeiten"
                    type="openingHours"
                    name="openingHours"
                />
            </FormGroup>
            <FormGroup>
                <Field
                    component={DataSelectField}
                    label="Apothekenanzahl Stadt/Ort"
                    type="pharmacyCount"
                    name="pharmacyCount"
                />
                <Field
                    component={DataSelectField}
                    label="Einwohnerzahl Stadt/Ort"
                    type="population"
                    name="population"
                />
                <Field
                    component={FormikLocationSelect}
                    name="location"
                />
            </FormGroup>
            {isSignUp && (
                <FormGroup>
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="transferGK"
                        defaultChecked={false}
                        Label={{ label: (
                            <Typography variant="inherit">
                                Ich akzeptiere die <LinkWrap component={Link} to="/agb" target="_blank">Nutzungsbedingungen</LinkWrap>.
                            </Typography>
                        ) }}
                        margin="normal"
                        indeterminate={false}
                    />
                    <ErrorMessage
                        name="transferGK"
                        render={(msg) => <FormHelperText error>{msg}</FormHelperText>}
                    />
                    <Field
                        component={CheckboxWithLabel}
                        defaultChecked={false}
                        type="checkbox"
                        name="monthlySubscribed"
                        margin="normal"
                        indeterminate={false}
                        style={{ textAlign: 'left' }}
                        Label={{ label: 'Ich möchte am monatlichen ApoStar Tara Report teilnehmen.' }}
                    />
                </FormGroup>
            )}
            {
                (isSignUp ? values.monthlySubscribed : displaySoftware)
                    ? (

                        <HelperField
                            component={DataSelectField}
                            label="Verwendete Apothekensoftware"
                            type="software"
                            name="software"
                            help="Sie können am monatlichen ApoStar Tara Report nur teilnehmen, wenn Sie einen der in der drop down Liste angeführten Software Anbieter in Ihrer Apotheke verwenden"
                        />
                    )
                    : null
            }
        </>
    );
};

export default Form;
