import Report, { PeriodType, ReportType } from './Report';

import JSONFetch from './JSONFetch';
import ReportMapper from './ReportMapper';
import { urify } from './Helper';

const reportTypeParams = { general: 'A', pharmacy: 'E' } as const;

export default class ReportService {
    // Mapper to use
    mapper: ReportMapper;

    // The constructor
    constructor(mapper: ReportMapper) {
        this.mapper = mapper;
    }

    /**
     * Get most recent
     */
    getRecent(pharmacyId: number, type: ReportType): Promise<Array<Report>> {
        return JSONFetch({
            url: urify('/ArchivBerichteByApoID', { ApoID: pharmacyId, Typ: reportTypeParams[type] }),
            bodySerializer: () => '',
            responseParser: this.mapper.parseArray,
        });
    }

    /**
     * Get all
     */
    getAll(
        pharmacyId: number,
        type: ReportType,
        period: PeriodType,
        date?: Date,
        dateMode?: 'year' | 'month' | 'yearMonth',
    ): Promise<Array<Report>> {
        const dateYear = !date || date.getFullYear() < (new Date().getFullYear() - 20)
            ? {} : { Jahr: date.getFullYear() };

        const periodParams = ['yearly', 'all'].includes(period) ? {
            Herkunft: period === 'yearly' ? 'JABV' : 'ABV',
            ...dateYear
        } : {
            Herkunft: 'MABV',
            Monat: date ? date.getMonth() + 1 : null,
            ...dateYear
        };

        // Adjust from dateMode
        if (dateMode === 'year' && periodParams.hasOwnProperty('Monat')) {
            // @ts-ignore
            delete periodParams.Monat;
        }
        if (dateMode === 'month' && periodParams.hasOwnProperty('Jahr')) {
            // @ts-ignore
            delete periodParams.Jahr;
        }


        return JSONFetch({
            url: urify(
                '/ArchivBerichteByApoID',
                {
                    ApoID: pharmacyId,
                    Typ: reportTypeParams[type],
                    ...periodParams,
                },
            ),
            bodySerializer: () => '',
            responseParser: this.mapper.parseArray,
        });
    }
}
