import {
    MenuList,
    SvgIcon,
} from '@material-ui/core';

import FormMenuItem from './FormMenuItem';
import { ReactComponent as IconBalance } from '../../assets/apostar_icons_eingabe_4_bilanz.svg';
import { ReactComponent as IconGeneral } from '../../assets/apostar_icons_eingabe_1_allgdaten.svg';
import { ReactComponent as IconIncome } from '../../assets/apostar_icons_eingabe_3_gewinnverlust.svg';
import { ReactComponent as IconPharmacy } from '../../assets/apostar_icons_eingabe_1_apodaten.svg';
import { ReactComponent as IconStaff } from '../../assets/apostar_icons_eingabe_2_personal.svg';
import React from 'react';
import WarningMenuItem from './WarningMenuItem';
import useTypeStyles from '../Common/Ui/useTypeStyles';

export type FormMenuProps = {
    current?: string,
    onSelect: (item: string) => void,
    errorCount?: number,
    progress?: Record<'general' | 'balance' | 'income', { total: number, done: number }>,
};

const progressDisplay = (progress?: { total: number, done: number }) => (
    progress && progress.total > 0
        ? Math.round(100 * progress.done / progress.total) : 0
);

const FormMenu: React.FC<FormMenuProps> = ({
    onSelect,
    current,
    errorCount = 0,
    progress,
}) => {
    const classes = useTypeStyles();
    return (
        <MenuList style={{ padding: '1em' }}>
            <FormMenuItem
                label="Apothekendaten"
                onClick={() => onSelect('pharmacy')}
                icon={(
                    <SvgIcon style={{ height: 24 }} className={classes.yearlyText}><IconPharmacy /></SvgIcon>

                )}
                complete={0}
                active={current === 'pharmacy'}
            />
            <FormMenuItem
                label="Allgemeine Daten"
                onClick={() => onSelect('general')}
                icon={(
                    <SvgIcon style={{ height: 24 }} className={classes.yearlyText}><IconGeneral /></SvgIcon>
                )}
                complete={progressDisplay(progress?.general)}
                active={current === 'general'}
            />
            <FormMenuItem
                label="Personalstatisik"
                onClick={() => onSelect('staff')}
                icon={(
                    <SvgIcon style={{ height: 24 }} className={classes.yearlyText}><IconStaff /></SvgIcon>
                )}
                complete={0}
                active={current === 'staff'}
            />
            <FormMenuItem
                label="Gewinn- und Verlustrechnung"
                onClick={() => onSelect('income')}
                icon={(
                    <SvgIcon style={{ height: 24 }} className={classes.yearlyText}><IconIncome /></SvgIcon>
                )}
                complete={progressDisplay(progress?.income)}
                active={current === 'income'}
            />
            <FormMenuItem
                label="Bilanzpositionen"
                onClick={() => onSelect('balance')}
                icon={(
                    <SvgIcon style={{ height: 24 }} className={classes.yearlyText}><IconBalance /></SvgIcon>
                )}
                complete={progressDisplay(progress?.balance)}
                active={current === 'balance'}
            />
            {errorCount > 0 && (
                <WarningMenuItem
                    label={`${errorCount} Auffälligkeiten gefunden`}
                    onClick={() => onSelect('messages')}
                />)}
        </MenuList>
    );
};

export default FormMenu;
