import React, { useState } from 'react';

import CompareableNumberInput from './CompareableNumberInput';
import CompareableNumbersHeader from './CompareableNumbersHeader';
import { Error } from '../../Lib/YearlyDataValidation';
import FieldError from './FieldError';
import { IncomeSheet as IncomeSheetData } from '../../Lib/YearlyData';
import { getErrorsForField } from '../../Lib/ValidationHelpers';

// Define fields available
export const IncomeFields = {
    E1_LJ: 'Privatumsatz',
    E2_LJ: 'Krankenkassenumsatz',
    E3_LJ: 'davon Hochpreiser',
    E99_LJ: 'davon Covid19 Dienstleistungen',
    E4_LJ: 'Sonstige Erlöse eines Nebengewerbes',
    E50_LJ: 'Umsätze aus Weiterverrechnungen (zB. Einkaufsgemeinschaft)',
    E5_LJ: 'Sonstige betriebliche Erträge',
    E6_LJ: 'Materialaufwand',
    E7_LJ: 'Gehälter für Pharmazeuten',
    E8_LJ: 'Gehälter für PKA und sonstiges Hilfpersonal',
    E9_LJ: 'Abfertigungszahlungen (tatsächliche Auszahlungen minus allfälliger Umlagenvergütung)',
    E10_LJ: 'Saldo aus Dotierung/Auflösung Abfertigungs und Pensionsrückstellung',
    E11_LJ: 'Gesetzlicher Sozialaufwand',
    E13_LJ: 'Energiekosten',
    E14_LJ: 'Instandhaltungsaufwendungen für Betriebs- und Geschäftsausstattung',
    E15_LJ: 'KFZ und Fahrtkosten',
    E16_LJ: 'Rechts- und Beratungsaufwand',
    E17_LJ: 'Werbeaufwand',
    E18_LJ: 'Abschreibungen auf das Sachanlagevermögen',
    E19_LJ: 'Abschreibungen auf den Firmenwert',
    E20_LJ: 'Miete',
    E21_LJ: 'Pacht',
    E22_LJ: 'Prämien für betriebliche Versicherungen',
    E23_LJ: 'Steuern und öffentliche Abgaben (ohne Ust und Est)',
    E24_LJ: 'Beiträge zur Sozialversicherung des Dienstgebers',
    E25_LJ: 'Beiträge an Berufsvertretungen (ÖÄK, GHK, ÖAV)',
    E26_LJ: 'Sonstige Aufwendungen (ohne Dotierung Rücklagen)',
    E27_LJ: 'Finanzaufwand',
    E28_LJ: 'Saldo aus Dotierung/Auflösung von Rücklagen',
    E29_LJ: 'Rentenzahlungen pro Jahr',
    E30_LJ: 'Jahresüberschuss vor Steuern',
};

const helpTexts: Partial<Record<keyof typeof IncomeFields, string>> = {
    E11_LJ: 'Inklusive Lohnnebenkosten',
};

// Keys available in income sheet data
type IncomeKeys = keyof typeof IncomeFields;

/**
 * Properties of component
 */
type IncomeSheetProps = {
    year: number,
    isCurrentYear: boolean,
    onChange?: (values: Record<IncomeKeys, number | undefined>) => void,
    initialValues?: Record<IncomeKeys, number | undefined>,
    compareValues?: IncomeSheetData,
    errors?: Array<Error>,
};

/**
 * Income sheet form component
 */
const IncomeSheet: React.FC<IncomeSheetProps> = ({
    year,
    initialValues,
    onChange = () => {},
    compareValues,
    errors = [],
}) => {
    // Keep track of values in this form
    const [, setValues] = useState<Record<IncomeKeys, number | undefined>>(
        // @ts-ignore
        Object.fromEntries(
            Object.keys(IncomeFields).map((key) => ([key, initialValues?.[key] ?? undefined]))
        )
    );

    // Value change callback for individual field
    const onValueChange = (newValue?: number, id?: string) => {
        setValues((prev) => {
            const nextValues = {
                ...prev,
                [id ?? 'unknown']: newValue,
            };
            onChange(nextValues);
            return nextValues;
        });
    };

    return (
        <>
            <CompareableNumbersHeader year={year} />
            {Object.keys(IncomeFields).map((id, i) => (
                <FieldError
                    key={id}
                    errors={getErrorsForField(`income.${id}`, errors)}
                >
                    <CompareableNumberInput
                        id={id}
                        label={IncomeFields[id]}
                        tooltip={helpTexts[id]}
                        compareValue={compareValues?.[id]}
                        onChange={onValueChange}
                        initialValue={initialValues?.[id]}
                    />
                </FieldError>
            ))}
        </>
    );
}

export default IncomeSheet;
