import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    Link,
    ListItem,
    ListItemText,
    Paper,
    Theme,
    ThemeProvider,
    Typography,
} from '@material-ui/core';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import StaffForm, { calculateTotals } from './StaffForm';
import { YearlyData, YearlySaveData } from '../../Lib/YearlyData';

import BalanceSheet from './BalanceSheet';
import { CustomResponseError } from '../../Lib/JSONFetch';
import DataCompleteness from './DataCompleteness';
import { Error } from '../../Lib/YearlyDataValidation';
import ErrorMessages from './ErrorMessages';
import FormMenu from './FormMenu';
import GeneralForm from './GeneralForm';
import IncomeSheet from './IncomeSheet';
import Instructions from './Instructions';
import LoadingButton from '../Common/LoadingButton';
import NarrowContainer from '../Common/Ui/NarrowContainer';
import PharmacyDataForm from './PharmacyData';
import PharmacyService from '../../Lib/PharmacyService';
import Upload from './Upload';
import Validator from '../../Lib/YearlyDataValidation';
import YearlyDataService from '../../Lib/YearlyDataService';
import contextualTheme from './ContextTheme';
import { getCollectionYear } from '../../Lib/Dates';
import { getDefaultFiscalYearDates } from '../../Lib/Dates';
import { makeStyles } from '@material-ui/core/styles';
import { serverErrorMessage } from '../../Lib/Helper';
import useAuth from '../../Hooks/useAuth';
import useLeaveWarning from '../../Hooks/useLeaveWarning';
import useNotifications from '../../Hooks/useNotifications';
import useProgress from './useProgress';
import useService from '../../Hooks/useService';
import useTypeStyles from '../Common/Ui/useTypeStyles';

export type FormWizardProps = {};

const pages = {
    intro: 'intro',
    general: 'Allgemeine Daten',
    pharmacy: 'Apothekendaten',
    staff: 'Personalstatistik',
    income: 'Gewinn- und Verlustrechnung',
    balance: 'Bilanzpositionen',
    messages: 'Warnungen',
    upload: 'Upload Excel-Daten',
} as const;

const pageComponents = {
    pharmacy: PharmacyDataForm,
    general: GeneralForm,
    staff: StaffForm,
    income: IncomeSheet,
    balance: BalanceSheet,
    messages: BalanceSheet,
};

const useStyles = makeStyles((theme: Theme) => ({
    saveButton: {
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    formTitle: {
        textTransform: 'none',
    },
    extraLink: {
        fontWeight: 700,
        fontSize: '1em',
        paddingLeft: 10,
    },
    stickySave: {
        position: 'sticky',
        bottom: 0,
        textAlign: 'center',
        marginTop: -30,
        borderTop: 0,
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 30px, rgba(255,255,255,1) 100%)',
    },
}));

const leavingWarning = `Sie haben möglicherweise nicht gespeicherte Daten in diesem Formular.
Wollen Sie die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren.`;

/**
 * Multi page form wizard for data input
 */
const FormWizard: React.FC<FormWizardProps> = () => {
    // Service is used
    const service = useService<YearlyDataService>('yearlyData');
    const pharmacyService = useService<PharmacyService>('pharmacy');
    const { year } = useParams<{ year?: string }>();
    const { authentication } = useAuth();
    const { addSuccess, addError } = useNotifications();

    // Keep a reference to the current year, and also the requested year
    const currentYear = getCollectionYear();
    const requestedYear = year === `${currentYear - 1}` ? currentYear - 1 : currentYear;
    const isCurrentYear = currentYear === requestedYear;

    // Component state, current page and data
    const [currentPage, setCurrentPage] = useState<keyof typeof pages>('intro');
    const [errors, setErrors] = useState<Array<Error>>([]);
    const [validator, setValidator] = useState<Validator<any> | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [hasPharmacyChange, setHasPharmacyChange] = useState(false);
    const [isLoadingError, setIsLoadingError] = useState<string>();
    const [data, setData] = useState<YearlyData>({
        general: {},
        income: {},
        balance: {},
        staff: { rows: [] },
        isEmpty: true,
    });
    const [compareData, setCompareData] = useState<YearlyData>({
        general: {},
        income: {},
        balance: {},
        staff: { rows: [] },
        isEmpty: true,
    });
    const progress = useProgress(data);

    // Setup a warning that leaving will the page will trigger
    useLeaveWarning(leavingWarning, hasUnsavedChanges);

    // Data is loaded once
    useEffect(() => {
        setLoading(true);
        Promise.all([
            service.get(authentication!.pharmacy, requestedYear),
            service.get(authentication!.pharmacy, requestedYear - 1),
            service.getValidator(requestedYear),
        ])
            .then(([loadedData, compareValues, validator]): Promise<[YearlyData, YearlyData, Validator<any>]> => {
                // Remove when api is ready
                if (loadedData.isEmpty) {
                    return pharmacyService
                        .get(authentication!.pharmacyId)
                        .then((pharmacy) => ([{
                            ...loadedData,
                            pharmacy,
                        }, compareValues, validator]));
                }
                return Promise.resolve([loadedData, compareValues, validator]);
            })
            .then(([loadedData, compareValues, validator]) => {
                // Calculate staff data totals
                const combinedData = loadedData;
                const loadedStaffData = loadedData.staff || { rows: [] };
                combinedData.staff = { ...loadedStaffData, ...calculateTotals(loadedStaffData.rows) };

                if (loadedData.isEmpty) {
                    setData({
                        ...combinedData,
                        general: {
                            ...combinedData.general,
                            ...getDefaultFiscalYearDates(combinedData, requestedYear),
                            A4_LJ: false,
                            A6_LJ: false,
                            A7_LJ: false,
                            A19_LJ: false,
                            A13_LJ: compareValues.general.A13_LJ,
                            A14_LJ: compareValues.general.A14_LJ,
                        },
                    });
                }
                setData(combinedData);
                setCompareData(compareValues);
                setValidator(validator);
                validator.validate(combinedData, compareValues);
                setErrors(validator.errors);
                setLoading(false);
            })
            .catch((e) => {
                const errorMessage = e instanceof CustomResponseError
                    ? e.message
                    : (e.message.startsWith('7182:')
                        ? `
Möglichweise ist dieses Jahr noch nicht freigeschalten. Bitten wenden Sie sich
an den Apotherverband wenn dieses Problem weiterhin besteht.
                        `
                        : 'Server konnte nicht erreicht werden'
                    );
                setIsLoadingError(errorMessage);
            });
        // Do not reload when service changes, intentional!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestedYear]);

    // We need to push routes
    const history = useHistory();
    const changeYear = () => {
        history.push(`/jaehrlich/${isCurrentYear ? currentYear - 1 : ''}`);
    }

    // Saving function
    const triggerSave = (givenData: YearlyData) => {
        if (!validator) return;

        setLoading(true);

        const result = validator.validate(givenData, compareData);
        console.log("Validating data", result, validator.errors, validator.getResults());

        // Compose a save data object
        const saveData: YearlySaveData = {
            ...givenData,
            year: requestedYear,
            validationResult: validator.hasCriticalErrors() ? 'critical' : (
                result ? 'none' : 'warn'
            ),
            validationResults: validator.getResults(),
            // @ts-ignore, i dont know why ts says it's string | number | symbol when it is typed as string
            intermediates: validator.intermediateResults,
        };

        service.save(saveData, authentication!.pharmacy, requestedYear)
            .then(() => {
                setHasUnsavedChanges(false);
                if (hasPharmacyChange && data.pharmacy) {
                    return pharmacyService.save(data.pharmacy)
                        .then(() => setHasPharmacyChange(false));
                }
            })
            .then(() => {
                addSuccess('Die Daten wurden gespeichert');
            })
            .catch((e) => {
                addError(serverErrorMessage(e, 'Fehler! Daten konnten nicht gespeichert werden', true));
            })
            .finally(() => {
                setErrors(validator.errors);
                setLoading(false);
            });
    };

    // Save handler, save and finish load
    const onSaveClicked = () => {
        // Guard but should always be loaded
        if (!validator || !hasUnsavedChanges) {
            return;
        }
        triggerSave(data);
    };

    // All the classes
    const classes = useStyles();
    const typeClasses = useTypeStyles();
    const changeYearButton = (
        <Button variant="outlined" color="primary" onClick={changeYear} className={typeClasses.yearlyButtonOutlined}>
            {isCurrentYear ? 'zum Vorjahr' : 'zum aktuellen Jahr'}
        </Button>
    );

    // Memoize displayed section
    const displayedComponent = useMemo(() => {
        if (loading) {
            return (
                <div>
                    <CircularProgress />
                    <Typography variant="h4" className={typeClasses.yearlyText} gutterBottom>Bitte einen moment Geduld</Typography>
                    <Typography variant="body1">Daten werden geladen</Typography>
                </div>
            );
        }

        // Intoduction page has special treatment
        if (currentPage === 'intro') {
            return (
                <Instructions
                    missingLastYear={(isCurrentYear && (compareData.isEmpty || false))}
                    onRequestLastYear={changeYear}
                />
            );
        } else if (currentPage === 'messages') {
            return (<ErrorMessages errors={errors} headerAction={changeYearButton} />);
        } else if (currentPage === 'upload') {
            return (
                <Upload
                    onUpload={(uploadData) => {
                        setData(uploadData);
                        triggerSave(uploadData);
                    }}
                    current={data}
                    year={requestedYear}
                    pharmacyId={authentication!.pharmacyId}
                    action={changeYearButton}
                />
            );
        }

        // A component based on the current page
        const FormComponent = pageComponents[currentPage] ?? (() => (null));

        // Construct form UI
        return (
            <>
                <Card>
                    <CardHeader
                        title={pages[currentPage]}
                        titleTypographyProps={{ className: [classes.formTitle, typeClasses.yearlyText].join(' ') }}
                        action={changeYearButton}
                    />
                    <CardContent>
                        <FormComponent
                            year={requestedYear}
                            pharmacyId={authentication!.pharmacyId}
                            fiscalYear={[data.general?.A2_LJ, data.general?.A3_LJ]}
                            isCurrentYear={isCurrentYear}
                            initialValues={data[currentPage] as any}
                            compareValues={compareData[currentPage] as any}
                            errors={errors}
                            onChange={(values: any) => {
                                console.log("changed", currentPage, values);
                                setData((prev) => {
                                    console.log("prev was", prev);
                                    return {
                                        ...prev,
                                        [currentPage]: values,
                                    };
                                });
                                setHasUnsavedChanges(true);
                                if (currentPage === 'pharmacy') {
                                    setHasPharmacyChange(true);
                                }
                            }}
                        />
                    </CardContent>
                </Card>
                <Card className={classes.stickySave}>
                    <CardContent>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={loading}
                            onClick={onSaveClicked}
                            className={[classes.saveButton, typeClasses.yearlyButton].join(' ')}
                        >
                            Daten speichern
                        </LoadingButton>
                    </CardContent>
                </Card>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        loading,
        data,
        compareData,
        errors,
        isCurrentYear,
        requestedYear,
        classes.formTitle,
        classes.saveButton,
    ]);

    // Cannot load data
    if (isLoadingError) {
        return (
            <NarrowContainer>
                <Typography variant="h4" gutterBottom>Daten konnten nicht geladen werden</Typography>
                <Typography variant="body1" gutterBottom>
                    Der Server konnte nicht erreicht werden um Ihre Daten zu laden. Stellen Sie sicher, dass eine
                    Internetverbindung besteht und versuchen Sie es später erneut.
                </Typography>
                <Typography variant="body1">
                    Folgender Fehler ist aufgetreten:
                </Typography>
                <Typography variant="body1">
                    {isLoadingError}
                </Typography>
            </NarrowContainer>
        );
    }

    /**
     * General UI
     */
    const totalProgress = Object.values(progress).reduce(
        (memo, values) => ([memo[0] + values.done, memo[1] + values.total]),
        [0, 0]
    );
    return (
        <ThemeProvider theme={outer => ({ ...outer, palette: contextualTheme.palette })}>
            <Box textAlign="center" p={2}>
                <Typography variant="h2" gutterBottom className={typeClasses.yearlyText}>
                    Dateneingabe jährlicher ApoStar Bilanz Vergleich
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper>
                        <DataCompleteness
                            year={requestedYear}
                            value={totalProgress[1] > 0 ? Math.round(100 * totalProgress[0] / totalProgress[1]) : 0}
                        />
                        <FormMenu
                            current={currentPage}
                            onSelect={(p) => setCurrentPage(p as any)}
                            errorCount={errors.length}
                            progress={progress}
                        />
                        <Divider />
                        <ListItem>
                            <ListItemText>
                                <Link
                                    onClick={() => {setCurrentPage('intro')}}
                                    component="button"
                                    className={classes.extraLink}
                                >
                                    Anleitung
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText>
                                <Link
                                    onClick={() => {setCurrentPage('upload')}}
                                    component="button"
                                    className={classes.extraLink}
                                >
                                    Upload Excel-Datei
                                </Link>
                            </ListItemText>
                        </ListItem>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                    {displayedComponent}
                </Grid>
            </Grid>
            <Prompt when={hasUnsavedChanges} message={leavingWarning} />
        </ThemeProvider>
    );
};

export default FormWizard;
