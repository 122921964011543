const validate = (values: any) => {
    const errors = {};
    if (!values.foundingYear || parseInt(values.foundingYear) > new Date().getFullYear()) {
        // @ts-ignore
        errors.foundingYear = 'Bitte geben Sie ein gültiges Grundungsjahr an';
    }
    if ((!values.software || !values.software?.begID) && values.monthlySubscribed) {
        // @ts-ignore
        errors.software = 'Bitte geben Sie die verwendete Software an';
    }
    if (!values.openingHours || !values.openingHours?.begID) {
        // @ts-ignore
        errors.openingHours = 'Bitte geben Sie ein Öffnungszeiten an';
    }
    if (!values.pharmacyCount || !values.pharmacyCount?.begID) {
        // @ts-ignore
        errors.pharmacyCount = 'Bitte wählen Sie eine Apothekenanzahl aus an';
    }
    if (!values.population || !values.population?.begID) {
        // @ts-ignore
        errors.population = 'Bitte geben Sie eine Einwohnerzahl an';
    }
    if (!values.location || !Array.isArray(values.location)) {
        // @ts-ignore
        errors.location = 'Bitte geben Sie eine Lagespezifikation an';
    }
    if (!values.dateOfBalance) {
        // @ts-ignore
        errors.dateOfBalance = 'Bitte geben Sie Ihren Bilanzstichtag an';
    } else {
        const parsedTimestamp = Date.parse(values.dateOfBalance);
        const asDate = new Date(parsedTimestamp);
        const month = asDate.getMonth();
        const nextMonth = new Date(asDate.setDate(asDate.getDate() + 1)).getMonth();
        if(!parsedTimestamp || nextMonth === month)  {
            // @ts-ignore
            errors.dateOfBalance = 'Bitte geben Sie einen Bilanzstichtag am Monatsletzten an';
        }
    }
    if (!values.transferGK) {
        // @ts-ignore
        errors.transferGK = 'Bitte akzeptieren sie die Nutzungbedingungen';
    }

    return errors;
}

export default validate;
