import React, { useCallback } from 'react';
import {
    Switch,
    useHistory,
} from 'react-router-dom';

import About from './Components/Public/About';
import ChooseComparisonGroup from './Components/User/ChooseComparisonGroup';
import ComparisonGroups from './Components/ComparisonGroups';
import Contact from './Components/Public/Contact';
import Dashboard from './Components/Dashboard/Dashboard';
import Faq from './Components/Public/Faq';
import Imprint from './Components/Public/Imprint';
import LegalTerms from './Components/Public/LegalTerms';
import LostPassword from './Components/Public/LostPassword';
import PharmacyData from './Components/User/PharmacyData';
import PharmacyUsers from './Components/User/PharmacyUsers';
import PublicRoute from './Components/PublicRoute';
import RegisterBranch from './Components/User/RegisterBranch';
import ReportsArchive from './Components/ReportsArchive';
import RestrictedRoute from './Components/RestrictedRoute';
import SignIn from './Components/Public/SignIn';
import SignUp from './Components/Public/SignUp';
import Terms from './Components/Dashboard/Terms';
import UserForm from './Components/User/UserStandaloneForm';
import ValidationParams from './Components/ValidationParams';
import YearlyDataForm from './Components/YearlyData/FormWizard';

const routeStubs: string[] = [];

function Routes() {
    const history = useHistory();

    // Link callbacks
    const comparisonGroupsRequested = useCallback(() => {
        history.push('/manage-comparison-groups');
    }, [history]);
    const infoRequested = useCallback(() => history.push('/about'), [history]);
    const lostPasswordRequested = useCallback(() => history.push('/lost-password'), [history]);
    const registrationRequested = useCallback(() => history.push('/register'), [history]);
    const goBack = useCallback(() => history.goBack(), [history]);
    const goToHome = useCallback(() => history.push('/'), [history]);

    return (
        <Switch>
            {routeStubs.map((slug) => (
                <PublicRoute path={`/${slug}`} key={slug}>
                    <p>Route is not implemented: {slug}</p>
                </PublicRoute>
            ))}
            {/* Routes for admin */}
            <RestrictedRoute path="/manage-comparison-groups" roles={['Apothekerverband']}>
                <ComparisonGroups />
            </RestrictedRoute>
            <RestrictedRoute path="/manage-params" roles={['Apothekerverband']}>
                <ValidationParams />
            </RestrictedRoute>
            {/* Routes for logged in */}
            <RestrictedRoute path="/dashboard">
                <Dashboard onLink={comparisonGroupsRequested} />
            </RestrictedRoute>
            <RestrictedRoute path="/stammdaten">
                <UserForm />
            </RestrictedRoute>
            <RestrictedRoute path="/benutzer">
                <PharmacyUsers />
            </RestrictedRoute>
            <RestrictedRoute path="/apotheke" roles={['Hauptbenutzer']}>
                <PharmacyData />
            </RestrictedRoute>
            <RestrictedRoute path="/filiale" roles={['Hauptbenutzer']}>
                <RegisterBranch />
            </RestrictedRoute>
            <RestrictedRoute path="/monatlich/vergleichsgruppe">
                <ChooseComparisonGroup type="monthly" />
            </RestrictedRoute>
            <RestrictedRoute path="/jaehrlich/vergleichsgruppe">
                <ChooseComparisonGroup type="yearly" />
            </RestrictedRoute>
            <RestrictedRoute path="/jaehrlich/:year?">
                <YearlyDataForm />
            </RestrictedRoute>
            <RestrictedRoute path="/berichte">
                <ReportsArchive />
            </RestrictedRoute>
            {/* Routes for public */}
            <PublicRoute path="/about" allowAuthenticated>
                <About />
            </PublicRoute>
            <PublicRoute path="/impressum" allowAuthenticated>
                <Imprint />
            </PublicRoute>
            <PublicRoute path="/kontakt" allowAuthenticated>
                <Contact />
            </PublicRoute>
            <RestrictedRoute path="/terms">
                <Terms />
            </RestrictedRoute>
            <PublicRoute path="/faq" allowAuthenticated>
                <Faq />
            </PublicRoute>
            <PublicRoute path="/agb" allowAuthenticated>
                <LegalTerms />
            </PublicRoute>
            <PublicRoute path="/register">
                <SignUp onFinishedAction={goToHome} />
            </PublicRoute>
            <PublicRoute path="/lost-password">
                <LostPassword onBackClicked={goBack} />
            </PublicRoute>
            <PublicRoute path="/">
                <SignIn
                    onInfoClicked={infoRequested}
                    onPasswordLostClicked={lostPasswordRequested}
                    onRegisterClicked={registrationRequested}
                />
            </PublicRoute>
        </Switch>
    );
}

export default Routes;
