import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import FormikTouchErrors from '../Common/Form/FormikTouchErrors';
import LoadingButton from '../Common/LoadingButton';
import NarrowContainer from '../Common/Ui/NarrowContainer';
import Pharmacy from '../../Lib/Pharmacy';
import PharmacyDataForm from './PharmacyDataForm';
import PharmacyService from '../../Lib/PharmacyService';
import { Skeleton } from '@material-ui/lab';
import { localDate } from '../../Lib/Helper';
import useAuth from '../../Hooks/useAuth';
import useSaveWithNotification from '../../Hooks/useSaveWithNotification';
import useService from '../../Hooks/useService';
import validate from '../../Lib/FormValidations/Pharmacy';

const PharmacyData : React.FC<{}> = () => {
    const service = useService<PharmacyService>('pharmacy');
    const { authentication } = useAuth();
    const [data, setData] = useState<Pharmacy>();

    useEffect(() => {
        service.get(authentication!.pharmacyId).then((pharmacy) => setData(pharmacy));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const savePharmacy = useSaveWithNotification((formData) => service.save(
        Object.assign(data || {}, formData)
    ));

    if (!data) {
        return (
            <NarrowContainer>
                <Skeleton />
            </NarrowContainer>
        );
    }

    return (
        <Formik
            initialValues={{
                openingHours: data.openingHours,
                pharmacyCount: data.pharmacyCount,
                software: data.software,
                population: data.population,
                location: data.location,
                foundingYear: data.foundingYear || '',
                dateOfBalance: localDate(data.dateOfBalance) || '',
                transferGK: data.transferGK || false,
                monthlySubscribed: data.monthlySubscribed || false,
            }}
            validateOnChange={false}
            validate={values => validate(values)}
            onSubmit={(values, { setSubmitting }) => {
                savePharmacy({
                    ...(values as unknown as Pharmacy),
                    dateOfBalance: values.dateOfBalance ?
                        new Date(Date.parse(values.dateOfBalance)) : null,
                })
                    .finally(() => setSubmitting(false))
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form>
                    <FormikTouchErrors />
                    <NarrowContainer>
                        <PharmacyDataForm isSignUp={false} displaySoftware={data.monthlySubscribed} />
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={isSubmitting}
                            onClick={submitForm}
                            fullWidth
                        >
                            speichern
                        </LoadingButton>
                    </NarrowContainer>
                </Form>
            )}
        </Formik>
    );
};

export default PharmacyData;
