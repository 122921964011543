import {
    Card,
    CardActionArea,
    CardContent,
    Icon,
    Typography,
} from '@material-ui/core';

import React from 'react';

export type QuickActionProps = {
    icon: string,
    title: string,
    onClick: () => void,
    className: string,
};

const QuickAction = ({
    icon,
    title,
    onClick,
    className,
}: QuickActionProps) => (
    <Card className={className}>
        <CardActionArea onClick={onClick}>
            <CardContent>
                <Icon>
                    <img src={icon} alt="icon" style={{ height: 80 }} />
                </Icon>
                <Typography variant="h6" component="div" color="inherit">
                    {title}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
)

export default QuickAction;
